import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_5d7210f4 from 'nuxt_plugin_plugin_5d7210f4' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_workbox_0f7bb9b8 from 'nuxt_plugin_workbox_0f7bb9b8' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_nuxticons_b53f61a8 from 'nuxt_plugin_nuxticons_b53f61a8' // Source: ./nuxt-icons.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_4552a344 from 'nuxt_plugin_cookieuniversalnuxt_4552a344' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_1e535936 from 'nuxt_plugin_nuxtuseragent_1e535936' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_libplugin5eb6d30c_243d16fa from 'nuxt_plugin_libplugin5eb6d30c_243d16fa' // Source: ./lib.plugin.5eb6d30c.js (mode: 'all')
import nuxt_plugin_axios_49abbb9c from 'nuxt_plugin_axios_49abbb9c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_browserdetectplugin_4c9c51cc from 'nuxt_plugin_browserdetectplugin_4c9c51cc' // Source: ./browser-detect-plugin.js (mode: 'client')
import nuxt_plugin_fontawesome_733569de from 'nuxt_plugin_fontawesome_733569de' // Source: ./fontawesome.js (mode: 'all')
import nuxt_plugin_customeventclient_400f247d from 'nuxt_plugin_customeventclient_400f247d' // Source: ../plugins/polyfills/custom-event.client.js (mode: 'client')
import nuxt_plugin_elementclosestclient_5fd7f5e5 from 'nuxt_plugin_elementclosestclient_5fd7f5e5' // Source: ../plugins/polyfills/element-closest.client.js (mode: 'client')
import nuxt_plugin_alert_535fa891 from 'nuxt_plugin_alert_535fa891' // Source: ../plugins/alert.js (mode: 'all')
import nuxt_plugin_modalSwapGoldSuccess_0a57015e from 'nuxt_plugin_modalSwapGoldSuccess_0a57015e' // Source: ../plugins/modalSwapGoldSuccess.js (mode: 'all')
import nuxt_plugin_modalGetPoint_e9dcd334 from 'nuxt_plugin_modalGetPoint_e9dcd334' // Source: ../plugins/modalGetPoint.js (mode: 'all')
import nuxt_plugin_modalSwapGoldFailed_5d7075e0 from 'nuxt_plugin_modalSwapGoldFailed_5d7075e0' // Source: ../plugins/modalSwapGoldFailed.js (mode: 'all')
import nuxt_plugin_modalCompleteData_0e0d289d from 'nuxt_plugin_modalCompleteData_0e0d289d' // Source: ../plugins/modalCompleteData.js (mode: 'all')
import nuxt_plugin_modalConsentPrivacyPolicy_3eb663c0 from 'nuxt_plugin_modalConsentPrivacyPolicy_3eb663c0' // Source: ../plugins/modalConsentPrivacyPolicy.js (mode: 'all')
import nuxt_plugin_modalSurveySuccess_00a78125 from 'nuxt_plugin_modalSurveySuccess_00a78125' // Source: ../plugins/modalSurveySuccess.js (mode: 'all')
import nuxt_plugin_modalSurveyFailed_4494b069 from 'nuxt_plugin_modalSurveyFailed_4494b069' // Source: ../plugins/modalSurveyFailed.js (mode: 'all')
import nuxt_plugin_vueotp_48c387bb from 'nuxt_plugin_vueotp_48c387bb' // Source: ../plugins/vue_otp.js (mode: 'all')
import nuxt_plugin_auth_7f7561ce from 'nuxt_plugin_auth_7f7561ce' // Source: ../plugins/auth.js (mode: 'all')
import nuxt_plugin_dayjs_66ce5f68 from 'nuxt_plugin_dayjs_66ce5f68' // Source: ../plugins/dayjs.js (mode: 'all')
import nuxt_plugin_pixel_2ae051b2 from 'nuxt_plugin_pixel_2ae051b2' // Source: ../plugins/pixel.js (mode: 'client')
import nuxt_plugin_storeclient_07d55f49 from 'nuxt_plugin_storeclient_07d55f49' // Source: ../plugins/store.client.js (mode: 'client')
import nuxt_plugin_styleclient_2ac9c019 from 'nuxt_plugin_styleclient_2ac9c019' // Source: ../plugins/style.client.js (mode: 'client')
import nuxt_plugin_vidle_38156de3 from 'nuxt_plugin_vidle_38156de3' // Source: ../plugins/vidle.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule
const baseStoreOptions = { preserveState: process.client }

function registerModule (path, rawModule, options = {}) {
  return originalRegisterModule.call(this, path, rawModule, { ...baseStoreOptions, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: function () {
    return {
      htmlAttrs: { lang: 'id' },

      titleTemplate: (titleChunk) => {
        const siteTitle = "Charm Girl's Talk"

        return titleChunk && titleChunk !== siteTitle
          ? `${titleChunk}`
          : siteTitle
      },

      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          hid: 'description',
          name: 'description',
          content:
            'Tempatnya membahas semua hal tentang menstruasi dan seputar cewek dengan cara FUN! Tersedia dalam dua bagian, yaitu untuk orang tua dan untuk anak perempuan.',
        },
      ],

      link: [
        { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `${this.$store.state.info.baseURL}${this.$route.path}`,
        },
      ],

      script: [
        // Google Tag Manager
        {
          hid: 'gtm',
          innerHTML: `
           (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5QXML7G');
          `,
        },
        {
          async: true,
          src: 'https://www.googletagmanager.com/gtag/js?id=AW-10809865742',
        },
        {
          hid: 'gtm',
          innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-10809865742');
          `,
        },

        // Global site tag
        {
          async: true,
          src: 'https://www.googletagmanager.com/gtag/js?id=G-TGT7SM0M7N',
        },
        {
          hid: 'gtag',
          innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-TGT7SM0M7N');
            gtag('config', 'UA-181011158-1');
            gtag('config', 'AW-474981197');
          `,
        },
      ],

      noscript: [
        {
          hid: 'gtmnoscript',
          pbody: true,
          innerHTML: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PN4RQNK"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
        },
      ],

      __dangerouslyDisableSanitizersByTagID: {
        gtm: ['innerHTML'],
        gtmnoscript: ['innerHTML'],
        gtag: ['innerHTML'],
      },
    }
  },

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_5d7210f4 === 'function') {
    await nuxt_plugin_plugin_5d7210f4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_0f7bb9b8 === 'function') {
    await nuxt_plugin_workbox_0f7bb9b8(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxticons_b53f61a8 === 'function') {
    await nuxt_plugin_nuxticons_b53f61a8(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_4552a344 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_4552a344(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_1e535936 === 'function') {
    await nuxt_plugin_nuxtuseragent_1e535936(app.context, inject)
  }

  if (typeof nuxt_plugin_libplugin5eb6d30c_243d16fa === 'function') {
    await nuxt_plugin_libplugin5eb6d30c_243d16fa(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_49abbb9c === 'function') {
    await nuxt_plugin_axios_49abbb9c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_browserdetectplugin_4c9c51cc === 'function') {
    await nuxt_plugin_browserdetectplugin_4c9c51cc(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_733569de === 'function') {
    await nuxt_plugin_fontawesome_733569de(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_customeventclient_400f247d === 'function') {
    await nuxt_plugin_customeventclient_400f247d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_elementclosestclient_5fd7f5e5 === 'function') {
    await nuxt_plugin_elementclosestclient_5fd7f5e5(app.context, inject)
  }

  if (typeof nuxt_plugin_alert_535fa891 === 'function') {
    await nuxt_plugin_alert_535fa891(app.context, inject)
  }

  if (typeof nuxt_plugin_modalSwapGoldSuccess_0a57015e === 'function') {
    await nuxt_plugin_modalSwapGoldSuccess_0a57015e(app.context, inject)
  }

  if (typeof nuxt_plugin_modalGetPoint_e9dcd334 === 'function') {
    await nuxt_plugin_modalGetPoint_e9dcd334(app.context, inject)
  }

  if (typeof nuxt_plugin_modalSwapGoldFailed_5d7075e0 === 'function') {
    await nuxt_plugin_modalSwapGoldFailed_5d7075e0(app.context, inject)
  }

  if (typeof nuxt_plugin_modalCompleteData_0e0d289d === 'function') {
    await nuxt_plugin_modalCompleteData_0e0d289d(app.context, inject)
  }

  if (typeof nuxt_plugin_modalConsentPrivacyPolicy_3eb663c0 === 'function') {
    await nuxt_plugin_modalConsentPrivacyPolicy_3eb663c0(app.context, inject)
  }

  if (typeof nuxt_plugin_modalSurveySuccess_00a78125 === 'function') {
    await nuxt_plugin_modalSurveySuccess_00a78125(app.context, inject)
  }

  if (typeof nuxt_plugin_modalSurveyFailed_4494b069 === 'function') {
    await nuxt_plugin_modalSurveyFailed_4494b069(app.context, inject)
  }

  if (typeof nuxt_plugin_vueotp_48c387bb === 'function') {
    await nuxt_plugin_vueotp_48c387bb(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7f7561ce === 'function') {
    await nuxt_plugin_auth_7f7561ce(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_66ce5f68 === 'function') {
    await nuxt_plugin_dayjs_66ce5f68(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pixel_2ae051b2 === 'function') {
    await nuxt_plugin_pixel_2ae051b2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_storeclient_07d55f49 === 'function') {
    await nuxt_plugin_storeclient_07d55f49(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_styleclient_2ac9c019 === 'function') {
    await nuxt_plugin_styleclient_2ac9c019(app.context, inject)
  }

  if (typeof nuxt_plugin_vidle_38156de3 === 'function') {
    await nuxt_plugin_vidle_38156de3(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }

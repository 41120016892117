//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import get from 'lodash/get'
import BootstrapModal from '../bootstrap/BootstrapModal'
import hasWindow from '~/mixins/hasWindow'
import { getCookies, removeCookies } from '~/utils/cookies'

export default {
  extends: BootstrapModal,
  mixins: [hasWindow],
  data() {
    return {
      errors: {},
      isSubmitting: false,
      consentPrivacyPolicy: false,
      hasScrolledToBottom: false,
    }
  },

  mounted() {
    this.$el.addEventListener(
      'app-hidden.bs.modal',
      () => this.$modalConsentPrivacyPolicy.hide(),
      {
        once: true,
      }
    )
    this.modal.show()
  },

  methods: {
    handleScroll(el) {
      if (
        el.srcElement.offsetHeight + el.srcElement.scrollTop >=
        el.srcElement.scrollHeight
      ) {
        this.hasScrolledToBottom = true
      }
    },
    async handleSubmit() {
      try {
        this.errors = {}
        this.isSubmitting = true
        await this.$axios.post(
          `v1/users/${this.$auth.user.id}/consent-privacy-policy`
        )
        await this.$auth._fetchUser()
        this.modal.hide()

        setTimeout(() => {
          const cookies = getCookies()
          const isShowCompleteData = Number(cookies.show_complete_data)
          if (isShowCompleteData) {
            this.$modalCompleteData.show()
            removeCookies('show_complete_data')
          }
        }, 200)
      } catch (error) {
        this.errors = get(error, 'response.data.errors', {})
        this.$alert.requestError(error)
      } finally {
        this.isSubmitting = false
      }
    },
  },
}

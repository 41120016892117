class ModalConsentPrivacyPolicy {
  constructor(context) {
    this._context = context
  }

  get showConsentPrivacyPolicy() {
    return this?._context.store?.state?.modal?.showConsentPrivacyPolicy
  }

  /**
   * Show.
   *
   */
  show() {
    this._context.store.commit('modal/setShowConsentPrivacyPolicy', true)
  }

  /**
   * Hide.
   *
   */
  hide() {
    this._context.store.commit('modal/setShowConsentPrivacyPolicy', false)
  }
}

export default (context, inject) => {
  const $modalConsentPrivacyPolicy = new ModalConsentPrivacyPolicy(context)
  inject('modalConsentPrivacyPolicy', $modalConsentPrivacyPolicy)
}

export default {
  state: () => ({
    showCompleteData: false,
    showSurveySuccess: false,
    showSurveyFailed: false,
    showSwapGoldSuccess: false,
    showSwapGoldFailed: false,
    showGetPoint: false,
    showConsentPrivacyPolicy: false,
  }),

  mutations: {
    setShowCompleteData: (state, showCompleteData) =>
      (state.showCompleteData = showCompleteData),
    setShowSurveySuccess: (state, showSurveySuccess) =>
      (state.showSurveySuccess = showSurveySuccess),
    setShowSurveyFailed: (state, showSurveyFailed) =>
      (state.showSurveyFailed = showSurveyFailed),
    setShowSwapGoldSuccess: (state, showSwapGoldSuccess) =>
      (state.showSwapGoldSuccess = showSwapGoldSuccess),
    setShowSwapGoldFailed: (state, showSwapGoldFailed) =>
      (state.showSwapGoldFailed = showSwapGoldFailed),
    setShowGetPoint: (state, showGetPoint) =>
      (state.showGetPoint = showGetPoint),
    setShowConsentPrivacyPolicy: (state, showConsentPrivacyPolicy) =>
      (state.showConsentPrivacyPolicy = showConsentPrivacyPolicy),
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import get from 'lodash/get'
// import DatePicker from 'vue2-datepicker'
import hasWindow from '~/mixins/hasWindow'
import 'vue2-datepicker/index.css'

export default {
  // components: { DatePicker },
  mixins: [hasWindow],
  props: {
    isModal: Boolean,
    email: String,
    // eslint-disable-next-line vue/require-default-prop
    modalHandleLogin: Function,
    // eslint-disable-next-line vue/require-default-prop
    hideModal: Function,
  },
  data() {
    return {
      dob: null,
      errors: {},
      input: {
        email: this.email,
      },
      isSubmitting: false,
      provinces: [],
    }
  },
  watch: {
    email(val, oldVal) {
      this.input.email = val
    },
  },
  beforeMount() {
    this.loadProvince()
  },
  methods: {
    async loadProvince() {
      try {
        const response = await this.$axios.get(`v1/provinces`, {
          params: {
            limit: 34,
            orderBy: 'name',
            orderDirection: 'asc',
          },
        })
        this.provinces = response.data.data
      } catch (e) {}
    },
    async handleSubmit() {
      try {
        this.errors = {}
        this.isSubmitting = true
        await this.$auth.register(this.input)
        if (this.isModal) {
          this.hideModal()
        }
      } catch (error) {
        this.errors = get(error, 'response.data.errors', {})
        this.$alert.requestError(error)
      } finally {
        this.isSubmitting = false
      }
    },
    handleSocialRegister(type) {
      const redirectTo =
        this.$route.query.redirectTo || window.location.pathname
      localStorage.setItem('redirectTo', redirectTo)
      localStorage.setItem('isRegister', true)
      this.$auth.loginWithSocial(type)
    },
    handleBack() {
      this.$route.back()
    },
  },
}

import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5141c62e&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarDesktop: require('/var/www/charm-app/components/NavbarDesktop.vue').default,NavbarMobile: require('/var/www/charm-app/components/NavbarMobile.vue').default,NavbarMobileDrawer: require('/var/www/charm-app/components/NavbarMobileDrawer.vue').default,Footer: require('/var/www/charm-app/components/Footer.vue').default,FooterMobile: require('/var/www/charm-app/components/FooterMobile.vue').default,AlertListModal: require('/var/www/charm-app/components/alert/AlertListModal.vue').default,AuthModalCompleteData: require('/var/www/charm-app/components/auth/AuthModalCompleteData.vue').default,AuthModalConsentPrivacyPolicy: require('/var/www/charm-app/components/auth/AuthModalConsentPrivacyPolicy.vue').default,RegistBeforeExit: require('/var/www/charm-app/components/modal/RegistBeforeExit.vue').default,AuthModalLogin: require('/var/www/charm-app/components/auth/AuthModalLogin.vue').default,AuthModalRegister: require('/var/www/charm-app/components/auth/AuthModalRegister.vue').default,ModalGetPoint: require('/var/www/charm-app/components/ModalGetPoint.vue').default,ModalSwapGoldFailed: require('/var/www/charm-app/components/profile/ModalSwapGoldFailed.vue').default,ModalSwapGoldSuccess: require('/var/www/charm-app/components/profile/ModalSwapGoldSuccess.vue').default,FloatingGachaIcon: require('/var/www/charm-app/components/icons/FloatingGachaIcon.vue').default})

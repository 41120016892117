//
//
//
//
//
//
//

import AuthCheckConsentPrivacyPolicy from './AuthCheckConsentPrivacyPolicy.vue'
export default {
  components: { AuthCheckConsentPrivacyPolicy },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasWindow from '~/mixins/hasWindow'
import FooterMobile from '~/components/FooterMobile'
import FloatingGachaIcon from '~/components/icons/FloatingGachaIcon'
import NavbarDesktop from '~/components/NavbarDesktop'
import AuthModalConsentPrivacyPolicy from '~/components/auth/AuthModalConsentPrivacyPolicy.vue'
import { getCookies, setCookies } from '~/utils/cookies'

export default {
  components: {
    AuthModalConsentPrivacyPolicy,
    FooterMobile,
    FloatingGachaIcon,
    NavbarDesktop,
  },
  mixins: [hasWindow],

  data() {
    return {
      visible: false,
      email: '',
      showFooter: true,
      modalData: {
        title: 'Mau kemana nih?',
        subtitle:
          'Untuk informasi tentang menstruasi dan perempuan lebih lanjut, registrasi sekarang yuk!',
      },
    }
  },
  watch: {
    '$route.query'() {
      this.handleGetPointModal()
    },
    $route(to, from) {
      if (
        to.fullPath === '/gold-point/gacha' ||
        to.fullPath === '/gold-point/trivia'
      ) {
        this.showFooter = false
      }
      this.showFooter = true
    },
    '$auth.user': {
      handler(user) {
        if (user) {
          this.handleCheckConsentPrivacyPolicy()
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollListener)
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
    window.addEventListener('scroll', this.handleScroll)
    // document.body.addEventListener('mouseleave', this.handleRegistModal)
  },
  methods: {
    scrollTop() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    scrollListener(e) {
      this.visible = window.scrollY > 150
    },
    onidle() {
      alert('You have been logged out')
    },
    handleCheckConsentPrivacyPolicy() {
      if (
        this.$auth.isLoggedIn &&
        !this.$auth.user.privacy_policy_consent_at &&
        this.$route.path !== '/privacy-policy'
      ) {
        setTimeout(() => {
          if (this.$route.path === '/') {
            const cookies = getCookies()
            const isShowCompleteData = Number(cookies.show_complete_data)
            if (isShowCompleteData) {
              this.$modalCompleteData.hide()
              setCookies('show_complete_data', '1')
            }
          }
          this.$modalConsentPrivacyPolicy.show()
        }, 1400)
      }

      if (this.$auth.isLoggedIn && this.$auth.user.privacy_policy_consent_at) {
        setTimeout(() => {
          this.handleGetPointModal()
        }, 1400)
      }
    },
    handleGetPointModal() {
      if (this.$auth.isLoggedIn) {
        if (
          this.$auth.user.privacy_policy_consent_at &&
          this.$route.query['is-register'] === '1' &&
          !localStorage.getItem('isRegister')
        ) {
          if (this.$refs.registBeforeExit.$el.className.includes('show')) {
            this.$refs.registBeforeExit.modal.hide()
          }
          this.$modalGetPoint.show()
          localStorage.setItem('isRegister', 1)
        }
      }
    },
    handleRegistModal(type) {
      if (!this.$auth.isLoggedIn) {
        if (!this.$refs.registBeforeExit.$el.className.includes('show')) {
          this.modalData = {
            title: 'Mau kemana nih?',
            subtitle:
              'Untuk informasi tentang menstruasi dan perempuan lebih lanjut, registrasi sekarang yuk!',
          }

          if (type === 2) {
            this.modalData = {
              title: 'Yuk join sekarang!',
              subtitle:
                'Jadilah Charm Girls terupdate tentang menstruasi & perempuan sekarang!',
            }
          }

          this.$refs.registBeforeExit.modal.show()
        }
      }
    },
  },
}
